<template>
  <img
    :src="themeConfig.app.appLogoImage"
    style="max-width:200px;height:100px"
  >
</template>
<script>
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      themeConfig: $themeConfig,
    }
  },
}
</script>
